import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	Container,
	Alert,
	Button,
	FormGroup,
	Label,
	InputGroup,
	InputGroupAddon,
	Input,
	InputGroupText,
	Row,
	Col,
} from "reactstrap";
import Widget from "../../components/Widget";
import { loginUser, setAccount, setTradingAccounts, setOfferNames } from "../../actions/user";
import { setChecking } from "../../actions/navigation";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Helper from "../../utils/Helper";
import Cookies from "js-cookie";
import APIServer from "../../utils/ServerApi";
import { KYCStatus } from "../../config/constants";
class Login extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
	};

	static isAuthenticated(token) {
		if (token) return true;
	}

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			options: {
				position: "top-right",
				autoClose: 5000,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: true,
			},
			email: "",
			password: "",
			errorMessage: null,
		};

		this.doLogin = this.doLogin.bind(this);
		this.changeEmail = this.changeEmail.bind(this);
		this.changePassword = this.changePassword.bind(this);
		this.signUp = this.signUp.bind(this);
	}

	changeEmail(event) {
		this.setState({ email: event.target.value });
	}

	changePassword(event) {
		this.setState({ password: event.target.value });
	}

	doLogin(e) {
		e.preventDefault();
		if (this.state.email === "" || this.state.password === "") {
			this.setState({ errorMessage: "Please fill all fields!" });
			return;
		}
		if (this.state.loading) return;
		this.setState({ loading: true });
		this.props.dispatch(setChecking(true));
		APIServer.post(`/api/auth/signin`, {
			email: this.state.email,
			password: this.state.password,
		})
			.then((res) => {
				if (res.data.success === false) {
					this.setState({ errorMessage: res.data.error });
					return;
				}
				localStorage.setItem("authenticated", res.data.body.accessToken);
				Cookies.remove("accessToken");
				Cookies.set("accessToken", res.data.body.accessToken, { expires: 1 });

				if (!res.data.body.isEmailVerified) {
					this.props.history.push("/verify-email");
					return;
				}

				this.props.dispatch(setAccount(res.data.body));

				if (res.data.body.verification_status === KYCStatus.APPROVED) {
					this.props.history.push("/app/accounts");
				} else {
					this.props.history.push("/app/profile");
				}
				this.setState({ loading: false });
			})
			.catch((err) => {
				this.setState({ loading: false });
				this.setState({ errorMessage: "Invalid username or password." });
			})
			.finally(() => {
				this.props.dispatch(setChecking(false));
			});
	}
	signUp() {
		this.props.history.push("/register");
	}
	onGoogleAuth = (values) => {
		process.env.REACT_APP_MODE === "local" && console.log("google auth", values);
	};

	render() {
		const { onFacebookAuth, onAppleAuth } = this.props;

		return (
			<div className="auth-page">
				<div>
					<a href={`${process.env.REACT_APP_PUBLIC_URL}`}>
						<span className="login-logo"></span>
					</a>
					<div className="auth-header">
						<div className="link-to-homepage-container">
							<a href={`${process.env.REACT_APP_PUBLIC_URL}`} target="_blank">
								Please click here to go to our homepage{" "}
							</a>
						</div>
					</div>
					{this.state.errorMessage && (
						<Container>
							<Alert
								className="alert-sm widget-middle-overflow rounded-2 text-center mb-2"
								style={{
									backgroundColor: "rgba(196,65,98,0.3)",
									maxWidth: "450px",
									border: "2px solid #c44162",
									margin: "auto",
								}}
							>
								{this.state.errorMessage}
							</Alert>
						</Container>
					)}
					<Container>
						<ul class="image-before">
							<li class="left-img">
								<img width="150" src="/auth-left.png" alt="" />
							</li>
							<li class="right-img">
								<img width="150" src="/auth-right.png" alt="" />
							</li>
						</ul>
						<Widget
							className="widget-auth mx-auto login-body"
							title={
								<h4 className="mt-0">
									<strong>Sign In</strong> to Client Portal
								</h4>
							}
						>
							<form onSubmit={this.doLogin}>
								<FormGroup className="mt">
									<InputGroup className="input-group-no-border">
										<InputGroupAddon addonType="prepend">
											<InputGroupText className="input-icon">
												<i className="la la-user text-white" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											id="email"
											className="input-transparent pl-3"
											style={{ padding: "0 0 0 10px" }}
											value={this.state.email}
											onChange={this.changeEmail}
											type="email"
											name="email"
											placeholder="Email"
										/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-no-border mt-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText className="input-icon">
												<i className="la la-lock text-white" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											id="password"
											className="input-transparent pl-3"
											value={this.state.password}
											style={{ padding: "0 0 0 10px" }}
											onChange={this.changePassword}
											type="password"
											name="password"
											placeholder="Password"
										/>
									</InputGroup>
								</FormGroup>
								<div className="bg-widget auth-widget-footer">
									<Row>
										<Col md={6}>
											<Button type="submit" className="auth-btn exxo-btn-primary" size="sm">
												{this.state.loading ? "Loading..." : "Sign In"}
											</Button>
										</Col>
										<Col md={6} className="text-center">
											<a className="mt-4 btn btn-link btn-block remind-link" href="/remind-link">
												Forgotten password?
											</a>
										</Col>
									</Row>
									<div className="login-or">OR</div>
									<Link className="mb-4 btn btn-default btn-block" to="register">
										Create a new Profile
									</Link>
								</div>
							</form>
						</Widget>
					</Container>

					<footer className="auth-footer">
						<div className="text-center">
							© 2020{" "}
							<a href={`${process.env.REACT_APP_PUBLIC_URL}`} target="blank">
								Exxo Markets LLC
							</a>
							<br />
							All Rights Reserved
						</div>
					</footer>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		isFetching: state.auth.isFetching,
		isAuthenticated: state.auth.isAuthenticated,
		errorMessage: state.auth.errorMessage,
	};
}

export default connect(mapStateToProps)(Login);
